<template>
    <div>
        <loadingComponent @closeDialog="dialog = $event" v-if="dialog" :dialogProps="dialog" :loadingProps="loading"
            :errorProps="error" />

        <!-- Edit button with tooltip -->
        <v-btn @click="openDialog" icon depressed class="ma-auto text-capitalize">
            <v-icon :color="$colors[0]">mdi-text-box-edit</v-icon>
        </v-btn>

        <!-- Main Dialog -->
        <v-dialog v-model="dialogAddCompteur" persistent width="800" min-height="250">
            <v-card class="rounded-lg">
                <!-- Header -->
                <v-toolbar :color="$colors[0]" dark elevation="0" class="rounded-t-lg">
                    <v-toolbar-title class="text-h6 font-weight-medium">
                        Edition contrat LLUM
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="closeModal">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <!-- Content -->
                <v-card-text class="pt-6" v-if="ajouterUnAutreCompteur">
                    <v-form ref="form" lazy-validation>
                        <!-- Current Meter Section -->
                        <div class="mb-6">
                            <v-card outlined class="pa-4">
                                <div class="text-subtitle-1 font-weight-medium mb-4">
                                    Compteur Sélectionné
                                </div>
                                <div class="mb-4">
                                    <span class="text-body-2 grey--text">N° Compteur:</span>
                                    <span class="ml-2 text-body-2 font-weight-medium">{{ numCompteur }}</span>
                                </div>
                                <v-text-field v-if="currentCompteurNeedsGRD" v-model="currentCompteurGRD"
                                    label="GRD pour compteur actuel" :rules="[rules.required]" outlined dense
                                    hide-details="auto" class="mt-2"></v-text-field>
                            </v-card>
                        </div>

                        <!-- Additional Meters Section -->
                        <div class="mb-6">
                            <v-card outlined class="pa-4">
                                <div class="text-subtitle-1 font-weight-medium mb-4">
                                    Compteurs Additionnels
                                </div>
                                <v-autocomplete v-model="selectedCompteur" :items="filteredCompteurs"
                                    :loading="isLoading" hide-no-data hide-selected label="Sélectionner compteurs"
                                    placeholder="Rechercher un compteur..." prepend-icon="mdi-meter-electric" chips
                                    item-text="numCompteur" item-value="numCompteur" deletable-chips multiple
                                    return-object clearable outlined dense>
                                    <template v-slot:selection="{ item, index }">
                                        <v-chip small class="mr-2">
                                            <span>{{ item.numCompteur }}</span>
                                        </v-chip>

                                    </template>
                                    <template v-slot:item="{ item }">
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ item.numCompteur }}
                                            </v-list-item-title>

                                        </v-list-item-content>
                                    </template>
                                    <template v-slot:prepend-item>
                                        <v-list-item ripple @click="toggle" class="grey lighten-4">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <v-icon small class="mr-2">mdi-select-all</v-icon>
                                                    Sélectionner tout
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider></v-divider>
                                    </template>
                                </v-autocomplete>
                            </v-card>
                        </div>

                        <!-- GRD Information Section -->
                        <div v-if="selectedCompteursNeedingGRD.length > 0" class="mb-6">
                            <v-card outlined class="pa-4">
                                <div class="text-subtitle-1 font-weight-medium mb-4">
                                    Information GRD
                                </div>
                                <div v-for="compteur in selectedCompteursNeedingGRD" :key="compteur.numCompteur"
                                    class="mb-3">
                                    <v-text-field v-model="compteur.grd" :label="'GRD pour ' + compteur.numCompteur"
                                        :rules="[rules.required]" outlined dense hide-details="auto"></v-text-field>
                                </div>
                            </v-card>
                        </div>
                    </v-form>
                </v-card-text>

                <!-- Actions -->
                <v-card-actions class="pa-4">
                    <v-spacer></v-spacer>
                    <v-btn text @click="closeModal" class="mr-2">
                        Annuler
                    </v-btn>
                    <v-btn :color="$colors[0]" :disabled="!isFormValid" @click="createContrat" class="white--text">
                        <v-icon left small>mdi-file-upload</v-icon>
                        Générer contrat
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import loadingComponent from "../../components/ui/loading-component.vue"
import { PDFDocument } from 'pdf-lib'
import download from 'downloadjs'
import axios from 'axios'
import dayjs from "dayjs"

export default {
    name: "LLUM_ELEC",
    components: { loadingComponent },
    props: {
        donner: null,
        numCompteur: null,
        listCompteur: [],
        donnerCompteurs: [],
        societe: null,
        donnerEnedis: null
    },
    data() {
        return {
            rules: {
                required: value => !!value || 'Ce champ est obligatoire.',
                superiorToday: value => value > dayjs() || `La date doit être supérieure ou égale au ${dayjs().format('DD/MM/YYYY')}`
            },
            marge: null,
            selectedCompteur: [],
            listCompteurResult: [],
            dialogAddCompteur: false,
            ajouterUnAutreCompteur: false,
            dialog: false,
            error: false,
            loading: false,
            isLoading: false,
            messageDialoge: "",
            currentCompteurGRD: '',
            currentCompteurIsRegie: false,
        }
    },
    computed: {
        currentCompteurNeedsGRD() {
            return this.currentCompteurIsRegie === 'oui';
        },
        selectedCompteursNeedingGRD() {
            return this.selectedCompteur.filter(c => c.isRegie === 'oui');
        },
        isFormValid() {
            if (this.currentCompteurNeedsGRD && !this.currentCompteurGRD) {
                return false;
            }
            return !this.selectedCompteursNeedingGRD.some(c => !c.grd);
        },
        filteredCompteurs() {
            return this.listCompteurResult.filter(item =>
                (item.typeCompteur.includes('C4') || item.typeCompteur.includes('C3') || item.typeCompteur.includes('C2')) &&
                item.fournisseur === "LLUM"
            );
        }
    },
    methods: {
        prepareCompteurs() {
            const currentCpt = this.donnerCompteurs.find(cpt => cpt.NumCompteur === this.numCompteur);

            if (currentCpt) {
                this.currentCompteurIsRegie = currentCpt.resultat.isRegie;
            }

            this.listCompteurResult = this.donnerCompteurs
                .filter(cpt => {
                    const hasLlumOffer = cpt.resultat.result.some(offer => offer.fournisseur === "LLUM");
                    return this.listCompteur.includes(cpt.NumCompteur) &&
                        cpt.NumCompteur !== this.numCompteur &&
                        hasLlumOffer;
                })
                .map(cpt => {
                    const llumOffer = cpt.resultat.result.find(offer =>
                        offer.fournisseur === "LLUM"
                    );
                    return {
                        ...llumOffer,
                        numCompteur: cpt.NumCompteur,
                        isRegie: cpt.resultat.donnerCompteur.isRegie,
                        grd: cpt.resultat.donnerCompteur.isRegie === 'oui' ? '' : 'enedis',
                        adressePostale:cpt.resultat.dataEnedis.donneTechniqueInfo[0].data.adresse

                    };
                });
        },
        toggle() {
            this.selectedCompteur = [...this.filteredCompteurs];
        },
        closeModal() {
            this.dialogAddCompteur = false;
            this.ajouterUnAutreCompteur = false;
            this.selectedCompteur = [];
            this.currentCompteurGRD = '';
        },
        openDialog() {
            this.dialogAddCompteur = true;
            this.ajouterUnAutreCompteur = true;
            this.prepareCompteurs();
        },
        async createContrat() {
            if (!this.isFormValid) return;

            try {
                this.dialog = true;
                this.loading = true;
                this.error = false;
                this.messageDialoge = "";

                const dataToSend = {
                    donner: {
                        ...this.donner,
                        enedis: this.donnerEnedis.donneTechniqueInfo[0].result == "valide",
                        grd: this.currentCompteurNeedsGRD ? this.currentCompteurGRD : 'enedis',
                        adressePostale:this.donnerEnedis.donneTechniqueInfo[0].data.adresse

                    },
                    listCompteur: this.selectedCompteur.map(compteur => ({
                        ...compteur,
                        dateDebut: compteur.dateDebut.split('-').reverse().join('/'),
                        grd: compteur.isRegie === 'oui' ? compteur.grd : 'enedis'
                    })),
                    numCompteur: this.numCompteur,
                    societe: this.societe,
                };

                const response = await axios({

                    url: 'https://editioncontrat.volto.fr/LLUM_C4_SMART',
                    // url: 'http://127.0.0.1:3000/LLUM_C4_SMART',
                    method: 'POST',
                    data: dataToSend,
                    headers: { 'content-type': 'application/json' }
                });

                const doc = await PDFDocument.load(response.data);
                const pdfBytes = await doc.save();
                download(pdfBytes, `LLUM_${this.societe.Raison}_.pdf`, 'application/pdf');
            } catch (err) {
                console.error(err);
                this.error = true;
            } finally {
                this.dialog = false;
                this.loading = false;
                this.messageDialoge = "";
            }
        }
    }
}
</script>

<style>
.v-dialog {
    border-radius: 8px;
}

.v-card {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1) !important;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: 0;
}

.v-chip {
    font-size: 0.875rem;
}

.v-btn:not(.v-btn--round).v-size--default {
    padding: 0 20px;
}
</style>